<template>
  <div class="index restaurant-booking-senior">
    <!-- <van-nav-bar title="餐厅预定选择" /> -->
    <van-radio-group v-model="seniorCustom">
      <van-radio name="预订餐厅+甄选酒单" class="radio-sm" :class="{ 'active-radio' : seniorCustom == '预订餐厅+甄选酒单'}">预订餐厅+甄选酒单 
          <span class="active-content" v-if="seniorCustom == '预订餐厅+甄选酒单'">
            <span v-html="content.seatContent"></span>
          </span>
      </van-radio>
      <van-radio name="预订餐厅+甄选酒单+专属菜单" class="radio-sm" :class="{ 'active-radio' : seniorCustom == '预订餐厅+甄选酒单+专属菜单'}">预订餐厅+甄选酒单+专属菜单 
        <span class="active-content" v-if="seniorCustom == '预订餐厅+甄选酒单+专属菜单'">
            <span v-html="content.seatMenuContent"></span>
        </span>
      </van-radio>
      <van-radio name="预订餐厅+甄选酒单+专属菜单+餐酒搭配" 
          v-if="isBlackGold() && content.seatMenuWineContent != null && content.seatMenuWineContent !=''"
          class="radio-smw" 
          :class="{ 'active-radio' : seniorCustom == '预订餐厅+甄选酒单+专属菜单+餐酒搭配'}">预订餐厅+甄选酒单+专属菜单+餐酒搭配 
        <span class="active-content" v-if="seniorCustom == '预订餐厅+甄选酒单+专属菜单+餐酒搭配'">
            <span v-html="content.seatMenuWineContent"></span>
        </span>
      </van-radio>
    </van-radio-group>

  
    <div class="bottom-confirm">
      <div class="confirm-area">
        <van-button block type="primary" native-type="submit" @click="onSubmit">提交</van-button>
      </div>
    </div>

    <van-popup v-model:show="submitSuccessShow" round position="bottom" @click="onRestaurant" @click-overlay="onRestaurant">
      <div class="success-card">
        <!-- <van-icon name="success" /> -->
        <!-- <h3>提交成功</h3> -->
        <div class="success-info">
          预定信息已成功提交，待商家确认后会有确认短信通知您。
        </div>
        <div class="button-row">
          <van-button plain type="primary" @click="onRestaurant">继续餐厅预订</van-button>
          <van-button plain type="primary" @click="onRestaurantBookingDetails">查看预订详情</van-button>
        </div>
        
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs , onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from 'vant';
import { loading, clearLoading } from '../utils/loading';
import Controller from "./restaurant-booking-controller";
import "../css/restaurant-booking.scss";
import "../css/restaurant-booking-senior.scss";

export default {
  created(){
    Controller.onLoadScript();
  },
  setup() {
    const state = reactive({
      content: {},
      submitSuccessShow: false,
      restaurantId: null,
      personCount: null,
      bookingDate: null,
      bookingTime: null,
      seat: null,
      allergy: "",
      remark: "",
      seniorCustom: null,
      bookingId: 0,
      thirdPartyOrderId: null
    });
    const { id } = useRoute().params;
    const router = useRouter();
    state.restaurantId = id;
    const onSubmit = () => {
      loading();
      if( !state.seniorCustom) {
        clearLoading();
        Toast.fail("请选择预定选项！");
        return ;
      }
      Controller.onSubmit(state).then((result) => {
        state.submitSuccessShow = true ;
        state.bookingId = result.bookingId;
        state.thirdPartyOrderId = result.thirdPartyOrderId;
        clearLoading();
      }, err => {
        clearLoading();
        Toast.fail(err.message);
      })};
    // const onBack = () => router.back()
    const isBlackGold = () => Controller.isBlackGold();
    const onRestaurant = () => {
        state.submitSuccessShow = false;
        router.push("/restaurant");
    }
    const onRestaurantBookingDetails = () => {
        state.submitSuccessShow = false;
        if(!state.thirdPartyOrderId) {
           router.push("/restaurant-booking-details/" + state.bookingId)
           return ;
        }
        Controller.orderDetails(state.thirdPartyOrderId).then(
          () => state.submitSuccessShow = false , 
          () => router.push("/restaurant-booking-details/" + state.bookingId))
    }
    onMounted(() => Controller.onSeniorMounted(state))
    return {
      ...toRefs(state),
      onSubmit,
      // onBack,
      onRestaurant,
      onRestaurantBookingDetails,
      isBlackGold
    };
  },
};
</script>
