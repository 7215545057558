import RestaurantService from "../api/restaurant-service";
import RestaurantBookingService from "../api/restaurant-booking-service";
import { parseTime, getWeekDay, getWeekDayNumber, isPhoneNumber} from "../utils"
import { initEcScript , goEcOrderDetails } from "../utils/ec"
import Member from "../api/member";
import User from "../api/user";


let bookingState = {
    restaurantId: null,
    personCount: null,
    bookingDate: null,
    bookingTime: null,
    seat: null,
    allergy: "",
    remark: "",
    seniorCustom: "",
    personName: "",
    telphone: ""
};

export function resetState() {
    bookingState = {
        restaurantId: null,
        personCount: null,
        bookingDate: null,
        bookingDateObject: null,
        bookingTime: null,
        seat: null,
        allergy: "",
        remark: "",
        seniorCustom: "",
        personName: "",
        telphone: ""
    }
}

export function copyToBookingState(state) {
    bookingState.restaurantId = state.restaurantId
    bookingState.personCount = state.personCount
    bookingState.bookingDate = state.bookingDate
    bookingState.bookingDateObject = state.bookingDateObject;
    bookingState.bookingTime = state.bookingTime
    bookingState.seat = state.seat || ""
    bookingState.allergy = state.allergy || ""
    bookingState.remark = state.remark || ""
    bookingState.seniorCustom = state.seniorCustom || ""
    bookingState.personName = state.personName || ""
    bookingState.telphone = state.telphone || ""
}

export function copyToState(state) {
    state.personCount = bookingState.personCount
    state.bookingDate = bookingState.bookingDate
    state.bookingDateObject = bookingState.bookingDateObject;
    state.bookingTime = bookingState.bookingTime
    state.seat = bookingState.seat || ""
    state.allergy = bookingState.allergy || ""
    state.remark = bookingState.remark || ""
    state.seniorCustom = bookingState.seniorCustom || "";
    state.personName = bookingState.personName || ""
    state.telphone = bookingState.telphone || ""
}

function createPersonCount(max) {
    let persons = [];
    for (let i = 1; i <= max; i++) {
        persons.push(i)
    }
    return persons;
}

function buildAvaliableBookingTimes(times) {
    let dates = [];
    for (let i = 0; i < times.length; i++) {
        let current = new Date(times[i].date);
        let date = (current.getMonth() + 1) + "." + current.getDate();
        let day = getWeekDay(current);
        let avaliable = times[i].avaliable;
        let paused = times[i].paused;
        dates.push({
            date,
            day,
            avaliable,
            paused,
            dateObject: current
        })
    }
    return dates;
}

function buildAvaliableBookingHours(state, selectedDate) {
    //  let openingHours = state.openingHours;
    // qxd 使用预定时间
    let openingHours = state.bookingOpeningHours;
    let restaurant = state.restaurant;
    if( !openingHours || openingHours.length == 0) {
        return createTimes(restaurant.openHours || "10:00", restaurant.closeHours || "22:00");
    }
    let hours = [];
    let day = getWeekDayNumber(selectedDate);
    for (let i = 0; i < openingHours.length; i++) {
        let openingHour = openingHours[i];
        if( openingHour.begin <= day && openingHour.end >= day ) {
            hours = addOpeningHours(hours, openingHour.open, openingHour.close);
            hours = addOpeningHours(hours, openingHour.open2, openingHour.close2);
            hours = addOpeningHours(hours, openingHour.open3, openingHour.close3);
            break;
        }
    }
    return hours;
}

function addOpeningHours(hours, open, close) {
    if(!open && !close) {
        return hours;
    }
    let times = createTimes(open, close);
    for(let i = 0 ; i < times.length ; i++) {
        hours.push(times[i]);
    }
    return hours;
}

function createTimes(open, close) {
    let min = parseInt(open.split(":")[0]);
    let max = parseInt(close.split(":")[0]);
    let topenMinute = parseInt(open.split(":")[1]);
    let tcloseMinute = parseInt(close.split(":")[1]);
    let times = [];
    let start = topenMinute > 0 ? min * 2 + 1 : min * 2;
    for (let i = start; i <= max * 2; i++) {
        let hours = parseInt(i/2)
        times.push(hours + (i % 2 == 0 ? ":00" : ":30"));
    }
    if( tcloseMinute > 0) {
        times.push(max + ":30")
    }
    return times;
}

const onSubmit = state => {
    let bookingTime = state.bookingDateObject;
    // bookingTime.setMonth(parseInt(state.bookingDate.split(".")[0]) - 1);
    // bookingTime.setDate(parseInt(state.bookingDate.split(".")[1]));
    bookingTime.setHours(parseInt(state.bookingTime.split(":")[0]))
    bookingTime.setMinutes(parseInt(state.bookingTime.split(":")[1]));
    bookingTime.setSeconds(0)
    return RestaurantBookingService.seniorBooking({
        restaurantId: state.restaurantId,
        personName: state.personName,
        telphone: state.telphone,
        personNumber: state.personCount,
        bookingTime: parseTime(bookingTime),
        seat: state.seat,
        remark: state.remark,
        allergy: state.allergy,
        seniorCustom: state.seniorCustom,
        userLevel: User.getLevel()
    })
}

const onSubmitToSenior = state => {
    return new Promise((reslove, reject) => {
        if (!state.bookingDate) {
            reject({
                bookingDateError: true
            });
        } else if (!state.bookingTime) {
            reject({
                bookingTimeError: true
            });
        } else if (!state.telphone || !isPhoneNumber(state.telphone) ) {
            reject({
                bookingTelephoneError: true
            });
        } else {
            copyToBookingState(state)
            reslove();
        }
    })
}
const onMounted = state => {
    RestaurantService.getRestaurantDetails(state.restaurantId)
        .then(r => {
            state.restaurant = r;
            // let open = parseInt((r.openHours || "10:00").split(":")[0]);
            // let close = parseInt((r.closeHours || "22:00").split(":")[0]);
            // state.times = createTimes(open, close);
            // state.times = [];
        });
    //qxd 使用预定时间作为选择
    RestaurantService.getRestaurantBookingOpeningHours(state.restaurantId).then(bookingHours => {
        state.bookingOpeningHours = bookingHours;
    });
    RestaurantService.getRestaurantOpeningHours(state.restaurantId).then(hours => {
        state.openingHours = hours;
    });

    RestaurantBookingService
        .getAvaliableBookingTimes(state.restaurantId)
        .then(times => {
            state.avaliableBookingTimes = times;
            state.dates = buildAvaliableBookingTimes(times);
        });
    state.personCounts = createPersonCount(4);
    // state.times = createTimes(10, 24);
    // state.dates = createDates(60);
    // state.bookingDate = createDateFormat(2).date;
    state.seats = ["大厅", "包厢"]
    Member.getMemberInfo().then(info => {
        state.personName = info.name;
        state.telphone = info.telphone;
    });
}
const onSeniorMounted = state => {
    copyToState(state)
    RestaurantService.getRestaurantSeniorContent(state.restaurantId)
        .then(content => state.content = content)
}
const onLoadScript = () => initEcScript(User.getClient());
const orderDetails = (orderNo) => goEcOrderDetails(orderNo);
const isBlackGold = () => User.isBlackGold();
const buildTimes = (state , selectedDate) => buildAvaliableBookingHours(state , selectedDate);

export default {
    onSubmit,
    onSubmitToSenior,
    onMounted,
    onSeniorMounted,
    onLoadScript,
    orderDetails,
    isBlackGold,
    buildTimes
}